import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import { restaurantsArrayPropTypes } from '@nandosaus/prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import React, { useEffect, useState } from 'react';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { useFeature } from '@hooks/use-feature';
import { AutocompleteInput } from '../autocomplete-input';
import { AutocompleteSuggestion } from '../autocomplete-suggestion';
import { AutocompleteSuggestionWrapper } from '../autocomplete-suggestion-wrapper';
import { getPlace } from '../get-place';
import { Label } from '../../typography';
import { LoadingIndicator } from '../../icons/loading-indicator';
import { RestaurantsList } from '../../restaurant-list';
import { useGoogleMaps } from '../../../hooks/use-google-maps';
import { analytics } from '../../../analytics';

const LocationAutocomplete = ({
  inputText,
  setInputText,
  disabled,
  countryCode,
  onActive,
  onBlur,
  onRestaurantClick,
  onSelect,
  predefinedPlaces,
  showResults,
  hasCurrentLocationEnabled,
}) => {
  const [isError, setIsError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const googleMaps = useGoogleMaps();

  const defaultToCurrentLocationAddressAutocomplete = useFeature('default-to-current-location-address-autocomplete');

  useEffect(() => {
    if (!defaultToCurrentLocationAddressAutocomplete) {
      return;
    }

    if (hasCurrentLocationEnabled || hasCurrentLocationEnabled === null) {
      // if flag is off here and null, set submitting to false to match legacy
      // before initialising location and if location is initialised, we dont want to display previous
      // the onchange will set this to false after it is loaded
      setIsSubmitting(true);
      return;
    }

    // if it has initialised but the user has declined, show previous location
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCurrentLocationEnabled]);

  const handleSuggestionSelection = async (value, placeId) => {
    try {
      setIsSubmitting(true);

      const place = await getPlace({ placeId });
      const coordinates = { latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() };

      setInputText(value);
      onSelect({ coordinates, name: value });
    } catch (error) {
      setIsError(!!error);
    }

    setIsActive(false);
    onBlur();
  };

  const handleChange = newValue => {
    setInputText(newValue);

    if (!isActive) {
      setIsActive(true);
      onActive();
    }

    if (newValue === '') {
      setIsSubmitting(false);
    } else if (!isSubmitting) {
      setIsSubmitting(true);
    }
  };

  const handleBlur = () => {
    setIsSubmitting(false);
    setIsActive(false);
    onBlur();
  };

  const searchOptions = {
    componentRestrictions: { country: countryCode.toLowerCase() },
  };

  if (googleMaps.status === 'loading') {
    return <LoadingIndicator />;
  }

  const onPreviousRestaurantClick = restaurant => {
    analytics.track(ANALYTICS_EVENTS.PREVIOUS_RESTAURANT_SELETED, {
      restaurantId: restaurant?.id,
    });
    onRestaurantClick(restaurant);
  };

  return (
    <PlacesAutocomplete
      autoFocus
      debounce={500}
      shouldFetchSuggestions={inputText.length > 3}
      onChange={handleChange}
      onSelect={handleSuggestionSelection}
      searchOptions={searchOptions}
      value={inputText}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <AutocompleteInput
            disabled={disabled}
            autoFocus
            loading={loading}
            getInputProps={getInputProps}
            isError={isError}
            placeholder="Search by location"
          />
          {!isSubmitting && !showResults && !isEmpty(predefinedPlaces) && (
            <>
              <Label fontWeight="400" mt={1} mb="0.5rem" color="greyPrimary" variant={2}>
                Previous restaurants
              </Label>
              <RestaurantsList onRestaurantClick={onPreviousRestaurantClick} restaurants={predefinedPlaces} />
            </>
          )}
          {isActive && !isEmpty(suggestions) && (
            <AutocompleteSuggestionWrapper handleBlur={handleBlur}>
              {suggestions.map(suggestion => (
                <AutocompleteSuggestion
                  showLocationIcon
                  {...getSuggestionItemProps(suggestion)}
                  formattedAddress={suggestion.formattedSuggestion}
                  description={suggestion.description}
                  key={suggestion.placeId}
                />
              ))}
            </AutocompleteSuggestionWrapper>
          )}
        </>
      )}
    </PlacesAutocomplete>
  );
};

LocationAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  inputText: PropTypes.string.isRequired,
  setInputText: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  onActive: PropTypes.func,
  onBlur: PropTypes.func,
  onRestaurantClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  predefinedPlaces: restaurantsArrayPropTypes,
  showResults: PropTypes.bool,
  hasCurrentLocationEnabled: PropTypes.bool,
};

LocationAutocomplete.defaultProps = {
  disabled: false,
  onActive: () => {},
  onBlur: () => {},
  onRestaurantClick: () => {},
  predefinedPlaces: null,
  showResults: false,
  hasCurrentLocationEnabled: false,
};

export { LocationAutocomplete };
