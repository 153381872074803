import { get } from 'lodash';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useRootStore } from '@nandosaus/state-management';
import React, { useEffect, useState } from 'react';

import { ORDER_TYPES } from '@nandosaus/constants';
import { useCurrentLocation } from '@hooks/use-current-location';
import { Box, Flex } from '../../grid';
import { CurrentLocationButton } from '../../current-location-button';
import { LocationAutocomplete } from '../../google-places-autocomplete';
import { RestaurantFinder } from '../restaurant-finder';
import { useFeature } from '../../../hooks/use-feature';

const RestaurantFinderScreen = ({ orderType, onSelect }) => {
  const { MemberStore, RestaurantStore } = useRootStore();
  const [searchLocation, setSearchLocation] = useState();
  const [searchActive, setSearchActive] = useState(false);
  const [inputText, setInputText] = useState('');
  const [hasCurrentLocationEnabled, sethasCurrentLocationEnabled] = useState(null);
  const showResults = searchLocation !== undefined && !searchActive;
  const savedRestaurantsEnabled = useFeature('save-order-addresses-and-restaurants');
  const savedRestaurants = savedRestaurantsEnabled ? get(MemberStore, 'profile.availableRestaurants', []) : [];

  const defaultToCurrentLocationAddressAutocomplete = useFeature('default-to-current-location-address-autocomplete');

  const onRestaurantClick = restaurant => {
    onSelect(restaurant);
  };

  const setCurrentCoordinates = coordinates => {
    setInputText('Current Location');
    setSearchActive(false);
    setSearchLocation({
      coordinates,
      name: 'Current Location',
    });
    sethasCurrentLocationEnabled(true);
  };

  const onFailCurrentCoordinates = () => {
    sethasCurrentLocationEnabled(false);
  };

  const { errorMessage, setCurrentLocation } = useCurrentLocation({
    onSuccess: setCurrentCoordinates,
    onFail: onFailCurrentCoordinates,
    shouldUseDefaultCoordinates: false,
  });

  useEffect(() => {
    if (inputText === '') {
      setSearchActive(true);
    }
  }, [inputText]);

  // on component mount, ask user if they want to use their current location
  useEffect(() => {
    if (defaultToCurrentLocationAddressAutocomplete && !hasCurrentLocationEnabled && !errorMessage) {
      setCurrentLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column" minHeight={!showResults ? '615px' : undefined} px={1} py={{ _: '1.5rem', md: 1 }}>
      <Box mb={{ _: 1, md: '0.5rem' }}>
        <CurrentLocationButton setCoordinates={setCurrentCoordinates} />
      </Box>
      <LocationAutocomplete
        inputText={inputText}
        setInputText={setInputText}
        hasCurrentLocationEnabled={hasCurrentLocationEnabled}
        countryCode={process.env.locationAutocomplete.countryCode}
        onActive={() => {
          setSearchActive(true);
        }}
        onBlur={() => {
          setSearchActive(false);
        }}
        onSelect={setSearchLocation}
        predefinedPlaces={savedRestaurants}
        onRestaurantClick={onRestaurantClick}
        showResults={showResults}
      />
      {showResults && (
        <RestaurantFinder
          orderType={orderType}
          findClosestRestaurants={RestaurantStore.closestRestaurants}
          onRestaurantClick={onRestaurantClick}
          searchLocation={searchLocation}
        />
      )}
    </Flex>
  );
};

RestaurantFinderScreen.propTypes = {
  onSelect: PropTypes.func.isRequired,
  orderType: PropTypes.oneOf([ORDER_TYPES.PICK_UP, ORDER_TYPES.DINE_IN, ORDER_TYPES.DELIVERY]).isRequired,
};

const ComposedRestaurantFinderScreen = observer(RestaurantFinderScreen);
export { ComposedRestaurantFinderScreen as RestaurantFinderScreen };
