import { selectedLocationPropTypes } from '@nandosaus/prop-types';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ANALYTICS_EVENTS, ORDER_TYPES } from '@nandosaus/constants';
import { Box, Flex } from '../../grid';
import { NoRestaurantResults } from './no-restaurant-results';
import { P } from '../../typography';
import { RestaurantMap } from '../../restaurant-map';
import { RestaurantsList } from '../../restaurant-list';
import { Tabs } from '../../tabs';
import { analytics } from '../../../analytics';

const MAP_VIEW = 'map';
const LIST_VIEW = 'list';

const options = [
  {
    value: LIST_VIEW,
    label: 'List View',
  },
  {
    value: MAP_VIEW,
    label: 'Map View',
  },
];

const MAX_RESULTS = 8;

const RestaurantFinder = ({ orderType, findClosestRestaurants, onRestaurantClick, searchLocation }) => {
  const [tab, selectTab] = useState(LIST_VIEW);
  const closestRestaurants = findClosestRestaurants(searchLocation.coordinates, MAX_RESULTS).map(
    result => result.restaurant
  );
  const restaurantsFound = closestRestaurants.length > 0;

  const onFinderRestaurantClick = restaurant => {
    analytics.track(ANALYTICS_EVENTS.FINDER_RESTAURANT_SELECTED, {
      restaurantId: restaurant?.id,
    });
    onRestaurantClick(restaurant);
  };

  return (
    <Box>
      {restaurantsFound && (
        <Flex flexDirection="row" justifyContent="center" background="white" pt={{ _: 2, md: 1 }} pb={0} mt="0.5rem">
          <Tabs options={options} activeTab={tab} selectTab={selectTab} />
        </Flex>
      )}
      <P
        color="greyPrimary"
        mt={`${restaurantsFound ? 1.125 : 1.5}rem`}
        mb={`${tab === LIST_VIEW ? 0.375 : 0.75}rem`}
        textAlign="center"
        variant={4}
      >
        Restaurants near &lsquo;{truncate(searchLocation.name, { length: 40 })}&rsquo;
      </P>
      {restaurantsFound ? (
        <>
          {tab === MAP_VIEW && (
            <RestaurantMap
              coordinates={searchLocation.coordinates}
              linkText="Select Restaurant"
              onRestaurantClick={onFinderRestaurantClick}
              restaurants={closestRestaurants}
            />
          )}
          {tab === LIST_VIEW && (
            <RestaurantsList
              orderType={orderType}
              coordinates={searchLocation.coordinates}
              onRestaurantClick={onFinderRestaurantClick}
              restaurants={closestRestaurants}
            />
          )}
        </>
      ) : (
        <Box mt="2.5rem" textAlign="center">
          <NoRestaurantResults />
        </Box>
      )}
    </Box>
  );
};

RestaurantFinder.propTypes = {
  onRestaurantClick: PropTypes.func.isRequired,
  findClosestRestaurants: PropTypes.func.isRequired,
  searchLocation: PropTypes.shape({
    coordinates: selectedLocationPropTypes.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  orderType: PropTypes.oneOf([ORDER_TYPES.PICK_UP, ORDER_TYPES.DINE_IN, ORDER_TYPES.DELIVERY]).isRequired,
};

export { RestaurantFinder };
