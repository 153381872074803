import { PropTypes } from 'prop-types';
import React from 'react';

import { useCurrentLocation } from '@hooks/use-current-location';
import { Alert } from '../alert';
import { Box, Flex } from '../grid';
import { Button } from '../button';
import { Icon } from '../icons';

const CurrentLocationButton = ({ setCoordinates, variant, shouldUseDefaultCoordinates }) => {
  const { errorMessage, isLoading, setCurrentLocation } = useCurrentLocation({
    shouldUseDefaultCoordinates,
    onSuccess: setCoordinates,
  });

  return (
    <Flex flexDirection="column">
      {/* Conditional used because children don't disappear with this setup when loading */}
      {/* TODO: change so conditional is not needed */}
      <Button variant={variant} onClick={setCurrentLocation} isLoading={isLoading}>
        {!isLoading && (
          <Flex justifyContent="center" alignItems="center">
            <Icon name="locationArrow" fill={variant === 'primary' ? 'white' : 'black'} flexShrink="0" mr="0.5rem" />
            Use current location
          </Flex>
        )}
      </Button>
      {errorMessage && (
        <Box mt="0.5rem">
          <Alert type="warning">{errorMessage}</Alert>
        </Box>
      )}
    </Flex>
  );
};

CurrentLocationButton.propTypes = {
  setCoordinates: PropTypes.func.isRequired,
  shouldUseDefaultCoordinates: PropTypes.bool,
  variant: PropTypes.string,
};

CurrentLocationButton.defaultProps = {
  shouldUseDefaultCoordinates: true,
  variant: 'secondary',
};

export { CurrentLocationButton };
