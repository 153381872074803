import { DEFAULT_COORDINATES } from '@nandosaus/constants';
import { useState } from 'react';

export const useCurrentLocation = ({ shouldUseDefaultCoordinates, onSuccess, onFail }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const setDefaultCoordinates = () => {
    onSuccess({ latitude: DEFAULT_COORDINATES.LATITUDE, longitude: DEFAULT_COORDINATES.LONGITUDE });
  };

  const success = position => {
    if (position.coords) {
      onSuccess(position.coords);
    } else if (shouldUseDefaultCoordinates) {
      setDefaultCoordinates();
    }

    setIsLoading(false);
  };

  const error = () => {
    if (shouldUseDefaultCoordinates) {
      setErrorMessage('Location data was denied, using default location');
      setDefaultCoordinates();
    } else {
      setErrorMessage('Location data was denied, please check your browser settings');
    }
    if (onFail) {
      onFail();
    }
    setIsLoading(false);
  };

  const setCurrentLocation = () => {
    setIsLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      setDefaultCoordinates();
      if (shouldUseDefaultCoordinates) {
        setErrorMessage('Geolocation is not supported by your browser');
      }
      setIsLoading(false);
    }
  };

  return { errorMessage, isLoading, setCurrentLocation };
};
